// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-adhesion-js": () => import("./../../../src/pages/adhesion.js" /* webpackChunkName: "component---src-pages-adhesion-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-boutique-js": () => import("./../../../src/pages/boutique.js" /* webpackChunkName: "component---src-pages-boutique-js" */),
  "component---src-pages-dons-js": () => import("./../../../src/pages/dons.js" /* webpackChunkName: "component---src-pages-dons-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

